import React from 'react'

const ArrowRight = () => {
  return (
<svg width="51" height="42" viewBox="0 0 51 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.0234 5.3098C33.9821 4.4523 32.3034 4.4523 31.2621 5.3098L13.6034 19.8523C12.7746 20.5348 12.7746 21.6373 13.6034 22.3198L31.2621 36.8623C32.3034 37.7198 33.9821 37.7198 35.0234 36.8623C36.0646 36.0048 36.0646 34.6223 35.0234 33.7648L19.6384 21.0773L35.0446 8.3898C36.0646 7.5498 36.0646 6.1498 35.0234 5.3098Z" fill="white"/>
</svg>


  )
}

export default ArrowRight