import React from 'react'

const Delete = () => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3386 7.44598C21.8626 6.93741 21.0935 6.93741 20.6175 7.44598L14.6483 13.8096L8.67906 7.43294C8.20299 6.92437 7.43395 6.92437 6.95788 7.43294C6.48181 7.94151 6.48181 8.76305 6.95788 9.27162L12.9271 15.6483L6.95788 22.025C6.48181 22.5336 6.48181 23.3551 6.95788 23.8637C7.43395 24.3723 8.20299 24.3723 8.67906 23.8637L14.6483 17.487L20.6175 23.8637C21.0935 24.3723 21.8626 24.3723 22.3386 23.8637C22.8147 23.3551 22.8147 22.5336 22.3386 22.025L16.3694 15.6483L22.3386 9.27162C22.8025 8.77609 22.8025 7.94151 22.3386 7.44598Z" fill="#690005"/>
    </svg>
  )
}

export default Delete